//import http from '../../http'
import http from '../../oauth-client'


export const state = {
    currentUser: loadState('auth.currentUser')
}

// if(loadState('auth.currentUser')) {
//     document.getElementById("tradingview-widget-container").style.display = 'block';
// }


export const mutations = {
    SET_CURRENT_USER(state, newValue) {

        
        if(newValue) {
            const user = {
                id: newValue.IdentityId,
                username: newValue.Name,
                roles: newValue.Roles,
                permissions: newValue.Permissions,
                isAdmin: newValue.Roles.find(a => a === 'administrator') ? true : false,
                isC8Internal: newValue.Roles.find(a => a === 'c8internal') ? true : false,
            };
    
            console.log('SET_CURRENT_USER', user);
            state.currentUser = user
            saveState('auth.currentUser', user)
        }
        else {
            localStorage.removeItem('auth.currentUser');
        }
    },
}

export const getters = {
    token(state) {
        return state.currentUser.token;
    },
    // Whether the user is currently logged in.
    loggedIn(state) {
        return !!state.currentUser
    },
}

export const actions = {
  

    // Logs in the current user.
   logIn({ dispatch, commit }, { userName, password } = {}) {
         return new Promise( (resolve, reject) => {
            http.login({username: userName, password}).then((response)=>{
              
                localStorage.removeItem('indices_list_data');
                localStorage.removeItem('index_view_history');
                localStorage.removeItem('indices_filter_criteria');

               
                commit('SET_CURRENT_USER', response.data);
                dispatch('signalr/connect', null, { root: true });
                dispatch('executionSignalr/connect', null, { root: true });
                // document.getElementById("tradingview-widget-container").style.display = 'block';
                resolve(response.data.token)
            }).catch((error) => {
            
                reject(error);
            });
        });
    },

    // Logs out the current user.
    logOut({ commit }) {
        // eslint-disable-next-line no-unused-vars
        localStorage.removeItem('indices_list_data');
        localStorage.removeItem('index_view_history');
        localStorage.removeItem('indices_filter_criteria');
        commit('SET_CURRENT_USER', null);
        // document.getElementById("tradingview-widget-container").style.display = 'none';
        http.clearTokens();
    }
}

// ===
// Private helpers
// ===

function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}


function loadState(key) {
    const b =  window.localStorage.getItem(key);
    if (b) {
        return JSON.parse(b);
    }
    return null;
}
