<template>
  <div id="app" :class="{'vhapp': isVH, 'c8studioapp': !isVH}">
    <router-view />
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  computed: {
    isVH() {
      return process.env.VUE_APP_VH === 'yes';
    },
  },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  created() {
    this.$store.dispatch('signalr/connect');
    this.$store.dispatch('executionSignalr/connect');

    // if(this.isVH) {
    //   var link = document.getElementById('dynamicIcon');
    //   link.href = '/favicon_vh.ico';
    // }
   
  },
};
</script>